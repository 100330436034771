import React, { useState, useEffect } from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import UPCard from "../components/UPCard";
import {Flex, PageWrapper, Title} from "../components/UI";
import styled from "styled-components";
import LocationItem from "../components/LocationItem";
import Marker from '../components/Marker'
import GoogleMapReact from 'google-map-react'
import { mapOptions } from '../styles/mapConfig'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import _ from 'lodash'
import locationData from '../data/locations.json'
import SearchBox from '../components/SearchBox'
import { breakpoints } from '../utils/helper'
import useLocalStorage from '../utils/useLocalStorage'


const StyledTitle = styled(Title)`
    color: ${props => props.theme.colors.black};
    text-align: left;
    padding: 72px 30px 0;
    
    @media ${breakpoints.large} {
        padding: 78px 0 0;
    }
`

const LinkBox = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 30px 0;
    margin-bottom: 42px;
    
    @media ${breakpoints.tablet} {
        flex-direction: row;
    }
    
    @media ${breakpoints.large} {
        padding: 24px 0 0;
        margin-bottom: 84px;
    }    
`

const LocationLink = styled(AnchorLink)`
    color: ${props => props.theme.colors.red};
    padding: 0 42px 0 0;
    text-decoration: none;
    ${props => props.theme.fontStyle.h4}
`

const MapContainer = styled(Flex)`
    margin: 0 auto;
    width: 100%;
    height: 320px;
    align-items: center;
    background-color: ${props => props.theme.colors.black};
    padding: 86px 24px 24px;

    @media ${breakpoints.tablet} {
        padding: 0;
        margin: 64px auto 0;
    }

    @media ${breakpoints.desktop} {
        height: 550px;
        margin: 90px auto 0;
    }
    
    .map {
        display: none;
        width: 100%;
        height: 100%;

        @media ${breakpoints.tablet} {
            display: block;
        }
    }
`

const LocationsPage = ({location}) => {
    const [geo] = useLocalStorage('geo')
    const [center, setCenter] = useState({'lat': 52.5570641, 'lng': 4.7837753})
    const [updateMap, setUpdateMap] = useState(false)

    const showCurrentLocation = (geo) => {
        setCenter(geo)
        setUpdateMap(true)
    }

    const handleDrag = () => setCenter({'lat': 52.6283939, 'lng': 4.888174})

    useEffect(() => {
        if(_.includes(location.search, '?loc') || updateMap) {
            setCenter(geo)
        }
        // eslint-disable-next-line
    }, [geo])

    return (
        <Layout location={location}>
            <SEO title="Vestigingen | Op deze plekken kun je UP Carwash vinden"
                 description="Waar zit UP Carwash? We hebben carwash vestigingen in Alkmaar, Beverwijk en Castricum, waar we met alle aandacht jouw auto wassen."/>
            <MapContainer alignItems="center">
                <div className="map">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAVSrtrwb69LSfYFvuBhWFCIFe_0M0EjBI' }}
                        defaultCenter={
                            [52.5570641, 4.7837753]
                        }
                        defaultZoom={11}
                        center={center}
                        options={mapOptions}
                        onDrag={handleDrag}
                    >
                        {locationData.locations.map((data, index) =>
                            <Marker
                                key={`${index}-marker`}
                                lat={data.lat}
                                lng={data.lng}
                            />
                        )}
                    </GoogleMapReact>
                </div>
                <SearchBox color="#FF0900" onCurrentLocation={showCurrentLocation}/>
            </MapContainer>
            <PageWrapper>
                <StyledTitle>Vestigingen</StyledTitle>
                <LinkBox>
                    {locationData.locations.length > 1 && locationData.locations.map((data, i) =>
                        <LocationLink key={`${i}-link`} to={`/vestigingen#${_.toLower(data.city)}`} stripHash>
                            {data.city}
                        </LocationLink>)}
                </LinkBox>
                <Flex flexDirection="column">
                    {locationData.locations.map((data, i) =>
                        <LocationItem key={`${i}-item`} id={`${_.toLower(data.city)}`} data={data} link address/>
                    )}
                </Flex>
            </PageWrapper>
            <UPCard/>
        </Layout>
    )
}

export default LocationsPage
